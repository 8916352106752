module.exports = {
  siteTitle: "Hi! I'm Takahiko Wada!",
  siteDescription: `Takahiko's portfolio`,
  keyWords: ['gatsbyjs', 'react', 'curriculum'],
  authorName: 'Takahiko Wada',
  twitterUsername: 'takahi5',
  githubUsername: 'takahi5',
  authorAvatar: '/images/profile.jpg',
  authorDescription: `Senior software engineer with 15yrs expericence of mobile and web service development.<br>
  Holds master degree of Engineering and Technology Management.<br>
  Love React Native, React, firebase and Ruby on Rails`,
  skills: [
    {
      name: 'React Native',
      level: 80,
    },
    {
      name: 'React',
      level: 60,
    },
    {
      name: 'Javascript',
      level: 70,
    },
    {
      name: 'Ruby on Rails',
      level: 80,
    },
    {
      name: 'MySQL',
      level: 70,
    },
    {
      name: 'Firebase',
      level: 60,
    },

    /* more skills here */
  ],
  jobs: [
    /* more jobs here */
    {
      company: 'stand.fm',
      begin: {
        month: 'jan',
        year: '2021',
      },
      duration: null,
      occupation: 'software engineer',
      description: 'Voice media app. (React Native / Node.js)',
    },
    {
      company: 'Zelohas LLC',
      begin: {
        month: 'aug',
        year: '2019',
      },
      duration: null,
      occupation: 'Freelance engineer',
      description:
        'Development of mobile apps. (React Native / React / Firebase)',
    },
    {
      company: 'maricuru',
      begin: {
        month: 'jun',
        year: '2017',
      },
      duration: '2 yrs',
      occupation: 'CTO',
      description:
        'Development of mobile apps. (React Native / Ruby on Rails / React)',
    },
    {
      company: 'DeNA',
      begin: {
        month: 'oct',
        year: '2010',
      },
      duration: '6 yrs',
      occupation: 'Backend Engineer',
      description: 'Development of mobile game and communication apps',
    },
    {
      company: 'Saki Corp',
      begin: {
        month: 'apr',
        year: '2005',
      },
      duration: '5 yrs e 6 mos',
      occupation: 'Software Engineer',
      description: 'Development of image recognition library with c and c++.',
    },
  ],
  portfolio: [
    {
      image: '/images/udemy_banner.jpg',
      description: 'Udemy: React Native course',
      url:
        'https://www.udemy.com/course/react-native-first-step/?referralCode=05B579F15272BFA71DE1',
    },
    {
      image: '/images/udemy-firebase-banner.png',
      description: 'Udemy: React Native x Firebase',
      url:
        'https://www.udemy.com/course/react-native-firebase/?referralCode=874ED7DC617B86DC35FC',
    },
    /* more portfolio items here */
  ],
  social: {
    twitter: 'https://twitter.com/takahi5',
    linkedin: 'https://www.linkedin.com/in/takahiko-wada-036a8813/',
    github: 'https://github.com/takahi5',
    blog: 'https://blog.takahi5.com',
    //email: 'yoshi.df@gmail.com',
  },
  siteUrl: 'https://takahi5.com',
  pathPrefix: '/gatsby-starter-cv', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover.jpeg',
  googleAnalyticsId: 'UA-156983249-2',
  background_color: '#ffffff',
  theme_color: '#25303B',
  fontColor: '#000000cc',
  enableDarkmode: true, // If true, enables dark mode switch
  display: 'minimal-ui',
  icon: 'src/assets/icon.png',
  headerLinks: [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: 'Portfolio',
      url: '/portifolio',
    },
  ],
}
